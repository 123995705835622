import { useCallback, useEffect, useRef, useState } from "react";
import { userState } from "../../interface/MainInterface";
import { GetServiceUsageParam, GetTeamStatisticsListParam, HttpMainApi } from "../../interface/main-api";
import dayjs, { Dayjs } from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import { Button, Grid, Paper, Stack } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { CommonUtils } from "../../utils/common_utils";

import "./ServiceUsage.css"
import ServiceUsageChart from "./ServiceUsageChart";

interface propsType {
  userState: userState;
}

interface IGridCols {
  user_id: string;
  team: string;
  content_cnt: number;
}

const mainApi = new HttpMainApi();
const cUtils = new CommonUtils();

const ServiceUsage = (props: propsType) => {
  const gridApi = useGridApiRef();

  const [isLoading, setIsLoading] = useState(false);
  const toastRef: any = useRef();

  const [srchDateFrom, setSrchDateFrom] = useState<Dayjs | null>(dayjs().startOf("month")); // 이번달 시작일
  const [srchDateTo, setSrchDateTo] = useState<Dayjs | null>(dayjs()); // 오늘날짜

  const [dataRows, setDataRows] = useState<IGridCols[]>([]);
  const [chartRows, setChartRows] = useState<IGridCols[]>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const dataColumn: GridColDef[] = [
    {
      field: "user_id",
      type: "number",
      headerName: "계정",
      headerAlign: "center",
      align: "center",
      flex: 0.7
    },
    {
      field: "team",
      type: "number",
      headerName: "부서",
      headerAlign: "center",
      align: "center",
      flex: 1.4
    },
    {
      field: "content_cnt",
      type: "number",
      headerName: "API 호출 수",
      headerAlign: "center",
      align: "center",
      flex: 0.8
    },
  ];

  const handleSrchDateFromChange = (newValue: Dayjs | null) => {
    setSrchDateFrom(newValue);
  };
  const handleSrchDateToChange = (newValue: Dayjs | null) => {
    setSrchDateTo(newValue);
  };

  const fncSearch = useCallback(async () => {
    setIsLoading(true);
    const param: GetServiceUsageParam = {
      searchDateFrom: dayjs(srchDateFrom).format("YYYYMMDD"),
      searchDateTo: dayjs(srchDateTo).format("YYYYMMDD"),
      userState: props.userState
    };

    const res = await mainApi.get_service_usage(param);
    if (res.code === "200") {
      setDataRows(res.response.statistics_list);
      setChartRows([]);
    } else {
      toastRef.current?.toast("에러발생 : " + res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  }, [srchDateFrom, srchDateTo]);

  const fncCellClick = useCallback(async (param: GridCellParams) => {
    setIsLoading(true);
    const params: GetTeamStatisticsListParam = {
      searchDateFrom: dayjs(srchDateFrom).format("YYYYMMDD"),
      searchDateTo: dayjs(srchDateTo).format("YYYYMMDD"),
      team: param.row.user_id,
      userState: props.userState
    };
    const res = await mainApi.get_team_statistics_list(params);
    if(res.code === "200") {
      setChartRows(res.response.statistics_list);
    } else {
      toastRef.current?.toast("에러발생 : " + res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }

    setIsLoading(false);
  }, [srchDateFrom, srchDateTo])

  const fncDownloadExcel = () => {
    if (dataRows.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다. 검색 후 이용하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    // sheet header
    let sheetRow: any = [["계정", "부서", "API 호출 수"]];
    // 내용 생성
    for (const data of dataRows) {
      const detail = [data.user_id, data.team, data.content_cnt];
      sheetRow = [...sheetRow, detail];
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 100 }, { wpx: 150 }, { wpx: 100 }];
    const sheetName = "일별API호출현황";
    // 파일명
    const fileName =
      "[" +
      dayjs(srchDateFrom).format("YYYY-MM-DD") +
      "~" +
      dayjs(srchDateTo).format("YYYY-MM-DD") +
      "] 일별 API 호출현황" +
      ".xlsx";
    cUtils.downloadExcel(sheetRow, colWidth, sheetName, fileName);
  };

  useEffect(() => {
    fncSearch();
  }, [])

  return (
    <>
      <Grid container spacing={2} sx={{ paddingX: 1 }} component="div">
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Paper sx={{ p: 2}} elevation={5}>
              <Stack spacing={2} direction="row" alignItems="center">
                <Grid item xs={8}>
                  <Stack direction={{ xs: "column", sm: "row"}} spacing={2}>
                    <DesktopDatePicker 
                      label="조회시작일"
                      format="MM/DD/YYYY"
                      value={srchDateFrom}
                      onChange={handleSrchDateFromChange}
                    />
                    <DesktopDatePicker
                      label="조회종료일"
                      format="MM/DD/YYYY"
                      value={srchDateTo}
                      onChange={handleSrchDateToChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack spacing={1} direction="row" justifyContent="flex-end" >
                    <Button variant="contained" onClick={fncSearch}>
                      검색
                    </Button>
                  </Stack>
                </Grid>
              </Stack>
            </Paper>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2}} elevation={5}>
            <Stack spacing={2} direction="column">
              <div className="datatable-daily-make-content">
                <DataGrid 
                  apiRef={gridApi}
                  rows={dataRows}
                  columns={dataColumn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 100
                      }
                    }
                  }}
                  pageSizeOptions={[3, 10, 20, 50, 100]}
                  pagination
                  getRowId={(row) => row.user_id}
                  loading={isLoading}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => { setSelectedRow(newRowSelectionModel); }}
                  onCellClick={fncCellClick}
                />
              </div>
              <Button variant="contained" onClick={fncDownloadExcel} fullWidth>
                엑셀다운로드
              </Button>
            </Stack>
          </Paper>
        </Grid>
        {/* 차트영역 */}
        <Grid item xs={12} sm={9}>
          <Paper className="chart-daily-make-content" sx={{ p: 2 }} elevation={5}>
            <ServiceUsageChart dataRows={chartRows} type="line" />
          </Paper>
        </Grid>
        {/* 차트영역 */}
      </Grid>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  )
}

export default ServiceUsage;