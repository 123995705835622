import { useEffect, useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import {
  HttpMainApi,
  PutLikeInfoParam,
} from "../../interface/main-api";

interface propsType {
  range: string;
  userState: userState;
  callBack: any;
}

const mainApi = new HttpMainApi();

const MultiLike = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-up");

  const setClickLike = async () => {
    const param: PutLikeInfoParam = {
      range: props.range,
      userState: props.userState,
    };
    const res = await mainApi.put_like(param);
    if (res.code === "200") {
      res.response.result.isLike === true
        ? setClassName("fas fa-icon fa-thumbs-up active")
        : setClassName("fas fa-icon fa-thumbs-up");
      props.callBack();
    } else {
      console.error("setClickLike ERROR : ", res.response.error_msg);
    }
  };

  return <i className={className} onClick={setClickLike} />;
};

export default MultiLike;
