import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { userState } from "../../interface/MainInterface";
import AskPassword from "./AskPassword";
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { GetUserListParam, HttpMainApi, InitUserPasswordParam, SignUpParam, UpdateUserInfoParam } from "../../interface/main-api";
import { DataGrid, GridCellParams, GridColDef, GridFilterModel, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import "./MngUser.css"

interface propsType {
  userState: userState;
}

interface IUserGridCols {
  uesr_id: string;
  team: string;
}

const mainApi = new HttpMainApi();

const MngUser = (props: propsType) => {
  const toastRef: any = useRef();
  const gridApi = useGridApiRef();

  const [isLoading, setLoading] = useState(false);
  const [adminConfirm, setAdminConfirm] = useState(true);
  
  // 회원가입
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [team, setTeam] = useState("");
  const [admin, setAdmin] = useState(false);

  // 회원정보
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedIv, setSelectedIv] = useState("");
  const [selectedSalt, setSelectedSalt] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(false);

  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordConFirm, setInputPasswordConFirm] = useState("");
  const [passwordAlert, setPasswordAlert] = useState(false);

  const [userRows, setUserRows] = useState<IUserGridCols[]>([]);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const userColumn : GridColDef[] = [
    {
      field: "sk",
      headerName: "user_id",
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "user_info2",
      headerName: "team",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "user_type",
      headerName: "admin",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <span>
            {param.row.user_type === "1" ? "슈퍼어드민" : param.row.user_type === "2" ? "Y" : "N"}
          </span>
       )
      }
    }
  ]

  const pwdCallback = (result: boolean) => {
    setAdminConfirm(result);
  }

  const fncGetUserList = useCallback(async () => {
    setLoading(true);
    const params : GetUserListParam = {
      id: props.userState.id
    }
    const res = await mainApi.get_user_list(params);
    if(res.code === "200") {
      setUserRows(res.response.user_list);
    } else {
      toastRef.current?.toast(
        res.response.error_msg,
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
    }
    setLoading(false);
  }, []);

  const fncCellClick = useCallback((param: GridCellParams) => {
    setSelectedUserId(param.row.sk);
    setSelectedCompany(param.row.user_info1);
    setSelectedTeam(param.row.user_info2);
    setSelectedIv(param.row.iv);
    setSelectedSalt(param.row.salt);
    if(param.row.user_type === "1" || param.row.user_type === "2")
      setSelectedAdmin(true);
    else
      setSelectedAdmin(false);
  }, [])

  const fucSignUp = useCallback(async () => {
    if(userId === "" || password === "" || company === "" || team === "") {
      toastRef.current?.toast("필수값(*)을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    setLoading(true);

    const param : SignUpParam = {
      id: userId,
      password: password,
      company: company,
      team: team,
      isAdmin: admin,
    }
    const res = await mainApi.sign_up_user(param);
    if(res.code === "200") {
      toastRef.current?.toast("회원가입 성공", "success", 3000, { vertical: "top", horizontal: "center" });
      setUserId("");
      setPassword("");
      setCompany("");
      setTeam("");
      setAdmin(false);
      fncGetUserList();
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [userId, password, team, admin]);

  const fncUpdateUser = useCallback(async () => {
    if(selectedCompany === "" || selectedTeam === "" || inputPassword === "" || inputPasswordConFirm === "") {
      toastRef.current?.toast("필수값(*)을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if(inputPassword !== inputPasswordConFirm) {
      toastRef.current?.toast("입력한 비밀번호가 서로 다릅니다. 비밀번호를 확인해주세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    setLoading(true);

    const param : UpdateUserInfoParam = {
      id: selectedUserId,
      password: inputPassword,
      company: selectedCompany,
      team: selectedTeam,
      admin: selectedAdmin
    }

    const res = await mainApi.update_user_info(param);
    if(res.code === "200") {
      toastRef.current?.toast("회원 업데이트 성공", "success", 3000, { vertical: "top", horizontal: "center" });
      setSelectedUserId("");
      setSelectedCompany("");
      setSelectedTeam("");
      setSelectedAdmin(false);
      setInputPassword("");
      setInputPasswordConFirm("");
      fncGetUserList();
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [selectedCompany, selectedTeam, inputPassword, inputPasswordConFirm]);

  const fncInitPassword = useCallback(async () => {
    if(selectedUserId === "") {
      toastRef.current?.toast("선택된 계정이 없습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    let confirmText = `${selectedUserId} 계정의 비밀번호를 초기화 하시겠습니까?`
    if(!window.confirm(confirmText)) return;    

    setLoading(true);

    const param : InitUserPasswordParam = {
      id: selectedUserId,
    }
    const res = await mainApi.init_user_password(param);
    if(res.code === "200") {
      toastRef.current?.toast("비밀번호 초기화 성공", "success", 3000, { vertical: "top", horizontal: "center" });
      setSelectedUserId("");
      setSelectedCompany("");
      setSelectedTeam("");
      setSelectedAdmin(false);
      setInputPassword("");
      setInputPasswordConFirm("");
      fncGetUserList();
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [selectedUserId])

  useEffect(() => {
    fncGetUserList();
  }, [fncGetUserList]);

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
    }
    if (props.userState.userType === "3") {
      window.location.href = "/";
    } 
  }, [props.userState])

  return (
    <div>
      {!adminConfirm ? (
        <AskPassword callBack={pwdCallback} />
      ) : (
      <Grid container spacing={1} sx={{ paddingX: 1}} component="div">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            회원관리
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <Paper sx={{ p: 2, pb: 4}} elevation={5}>
            <Stack spacing={1} direction="column">
              <Typography sx={{fontSize: "16px"}} variant="h6">
                회원가입
              </Typography>
              <TextField 
                id="txtUserId"
                label="아이디"
                variant="outlined"
                size="small"
                value={userId}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter"}}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
                required
                fullWidth
              />
              <TextField 
                id="txtSignUpPassword"
                label="비밀번호"
                variant="outlined"
                size="small"
                value={password}
                type="password"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter"}}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                fullWidth
              />
              <TextField 
                id="txtTeam"
                label="회사"
                variant="outlined"
                size="small"
                value={company}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter"}}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                required
                fullWidth
              />
              <TextField 
                id="txtTeam"
                label="부서"
                variant="outlined"
                size="small"
                value={team}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Enter"}}
                onChange={(e) => {
                  setTeam(e.target.value);
                }}
                required
                fullWidth
              />
              <FormGroup>
                <FormControlLabel 
                  value="1"
                  control={<Checkbox
                    disableRipple 
                    checked={admin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setAdmin(e.target.checked);
                    }}
                  />} 
                  label="어드민 여부" 
                />
              </FormGroup>
              <Button variant="contained" onClick={fucSignUp}>
                가입
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
        {/* 회원 리스트 */}
          <Paper sx={{ p : 2}} elevation={5}>
            <Stack spacing={1} direction="column">
              <div className="datatable-models">
                <DataGrid 
                  apiRef={gridApi}
                  rows={userRows}
                  columns={userColumn}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 100
                      }
                    }
                  }}
                  pageSizeOptions={[3, 10, 20, 50, 100]}
                  getRowId={(row) => row.sk}
                  rowSelectionModel={selectedRow}
                  onRowSelectionModelChange={(newRowSelectionModel) => { setSelectedRow(newRowSelectionModel); }}
                  onCellClick={fncCellClick}
                />
              </div>
            </Stack>
          </Paper>
        </Grid>
        {/* 회원 상세 정보 */}
        <Grid item xs={12} sm={4}>
          <Paper sx={{ p: 2}} elevation={5}>
            <Stack spacing={1} direction="column">
              <Typography sx={{fontSize: "16px"}} variant="h6">
                회원수정
              </Typography>
              <TextField 
                id="txtUserId"
                label="user_id"
                variant="outlined"
                size="small"
                value={selectedUserId}
                type="search"
                autoComplete="off"
                fullWidth
                required
                disabled
              />
              <TextField 
                id="txtCompany"
                label="company"
                variant="outlined"
                size="small"
                value={selectedCompany}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedCompany(e.target.value);
                }}
                type="search"
                autoComplete="off"
                fullWidth
                required
              />
              <TextField 
                id="txtTeam"
                label="team"
                variant="outlined"
                size="small"
                value={selectedTeam}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSelectedTeam(e.target.value);
                }}
                type="search"
                autoComplete="off"
                fullWidth
                required
              />
              <TextField 
                id="txtPassword"
                label="password"
                variant="outlined"
                size="small"
                value={inputPassword}
                onChange={(e : ChangeEvent<HTMLInputElement>) => {
                  if(inputPasswordConFirm !== "" && e.target.value !== "" && e.target.value !== inputPasswordConFirm)
                    setPasswordAlert(true);
                  else
                    setPasswordAlert(false);
                  setInputPassword(e.target.value);
                }}
                type="password"
                autoComplete="off"
                fullWidth
                required
              />
              <TextField 
                id="txtPasswordConfirm"
                label="password confirm"
                variant="outlined"
                size="small"
                value={inputPasswordConFirm}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if(inputPassword !== "" && e.target.value !== "" && e.target.value !== inputPassword)
                    setPasswordAlert(true);
                  else
                    setPasswordAlert(false);
                  setInputPasswordConFirm(e.target.value);
                }}
                type="password"
                autoComplete="off"
                fullWidth
                required
              />
              {passwordAlert && <Typography
                sx={{
                  display: "inline",
                  color: "#FF0000",
                  fontWeight: "700",
                  fontSize: "0.8rem"
                }}
                component="span"
              >
                비밀번호가 서로 다릅니다.
              </Typography>}
              <FormGroup>
                <FormControlLabel 
                  value="1"
                  control={<Checkbox
                    disableRipple 
                    checked={selectedAdmin}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSelectedAdmin(e.target.checked);
                    }}
                  />} 
                  label="어드민 여부" 
                />
              </FormGroup>
              <Button variant="contained" onClick={fncInitPassword}>
                비밀번호 초기화
              </Button>
              <Button variant="contained" onClick={fncUpdateUser}>
                회원정보 수정
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    )}
    <LoadingCircle loading={isLoading} />
    <Toast ref={toastRef} />
    </div>
  )
};

export default MngUser;