import { ApiBase } from "./api-base";
import { userState, inputList } from "../interface/MainInterface";
import { appConfig } from "../config/Config";

//로그인 임시
export interface LoginProcParam {
  id: string;
  password: string;
}

export interface SignUpParam {
  id: string;
  password: string;
  company: string;
  team: string;
  isAdmin: boolean;
}

export interface GetUserListParam {
  id: string;
}

export interface UpdateUserInfoParam {
  id: string;
  password: string;
  company: string;
  team: string;
  admin: boolean;
}

export interface InitUserPasswordParam {
  id: string;
}

export interface GetDashboardParam {
  userState: userState;
  fromDateText: string;
  toDateText: string;
}

// 카피라이터
export interface MultiGptContent {
  type: string;
  idx: string;
  teamType: string;
  userState: userState;
  brand_text: string;
  add_keyword: string;
  add_title: string;
  pk: string;
  range: string;
}

export interface MakeGptContent {
  type: string;
  idx: string;
  userState: userState;
  brand_text: string;
  add_keyword: string;
  add_title: string;
  pk: string;
  range: string;
}
export interface GetHistoryInfoParam {
  pk: string;
  range: string;
}

export interface GetLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutLikeInfoParam {
  userState: userState;
  range: string;
}

export interface GetUnLikeInfoParam {
  userState: userState;
  range: string;
}

export interface PutUnLikeInfoParam {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
}

export interface GetMyHistoryListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetHistoryListParam {
  userState: userState;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface GetMyLikeListParam {
  userId: string;
  dataText: string;
  fromDateText: string;
  toDateText: string;
}

export interface SetModelInfo {
  model_info: {
    aatag: string;
    api_key: string;
    api_key_primary_val: string;
    gubun: string;
    host: string;
    post_url: string;
    preset_text: string;
    request_data: {
      includeAiFilters: string;
      includeProbs: string;
      includeTokens: string;
      maxTokens: number;
      repeatPenalty: number;
      restart: string;
      start: string;
      stopBefore: string;
      temperature: string;
      text: string;
      topK: number;
      topP: number;
    };
    request_id: string;
  };
}

export interface DelModelInfo {
  request_id: string;
  gubun: string;
}

export interface GetModelSelectList {
  idx: string;
}

export interface SetModelSelectList {
  model_select_list: any;
}

export interface SetReplaceList {
  replace_list: any;
}

export interface GetQnAListParam {
  userId: string;
}

export interface PostQnaParam {
  title: string;
  content: string;
  userState: userState;
}

export interface RePostQuestion {
  originQseq: string;
  title: string;
  content: string;
  userState: userState;
}

export interface GetStatisticsListParam {
  searchDateFrom: string;
  searchDateTo: string;
  userState: userState;
}

export interface GetServiceUsageParam {
  searchDateFrom: string;
  searchDateTo: string;
  userState: userState;
}

export interface GetTeamStatisticsListParam {
  searchDateFrom: string;
  searchDateTo: string;
  team: string;
  userState: userState;
}

export interface SetNoticeParam {
  notice: string;
  userState: userState;
}

export interface RemoveNoticeParam {
  sk: string;
  userState: userState;
}

export interface GetNoticeParam {
  userState: userState;
}

export class HttpMainApi extends ApiBase {
  onApiName(): string {
    return "AdminMainApi";
  }

  onUrlPath(): string {
    if (window.location.hostname === "localhost") return appConfig.testApiUrl;
    else return appConfig.mainApiUrl;
  }
  //--------------------------------------------------------------
  // 로그인
  //--------------------------------------------------------------
  public async login_proc(param: LoginProcParam) {
    const requestBody = {
      command: "get_user_info",
      userId: param.id,
      password: param.password,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async sign_up_user(params: SignUpParam) {
    const requestBody = {
      command: "sign_up_user",
      userId: params.id,
      password: params.password,
      company: params.company,
      team: params.team,
      admin: params.isAdmin,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_user_list(params: GetUserListParam) {
    const requestBody = {
      command: "get_user_list",
      userId: params.id
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_user_info(params: UpdateUserInfoParam) {
    const requestBody = {
      command: "update_user_info",
      userId: params.id,
      password: params.password,
      company: params.company,
      team: params.team,
      admin: params.admin
    }
    const response = this.requestPost(requestBody);
    return response;
  }

  public async init_user_password(params: InitUserPasswordParam) {
    const requestBody = {
      command: "init_user_password",
      userId: params.id
    }
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_dashboard(params: GetDashboardParam) {
    const requestBody = {
      command: "get_dashboard",
      userState: params.userState,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async multi_make_gpt_copy(params: MultiGptContent) {
    try {
      const requestBody = {
        command: "multi_make_gpt_copy",
        type: params.type,
        idx: params.idx,
        teamType: params.teamType,
        userState: params.userState,
        brand_text: params.brand_text,
        add_keyword: params.add_keyword,
        add_title: params.add_title,
        pk: params.pk,
        range: params.range,
      };

      const response = this.requestPost(requestBody);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  public async poll_gpt_info(params: MakeGptContent) {
    try {
      const requestBody = {
        command: "poll_gpt_info",
        type: params.type,
        idx: params.idx,
        userState: params.userState,
        brand_text: params.brand_text,
        add_keyword: params.add_keyword,
        add_title: params.add_title,
        pk: params.pk,
        range: params.range,
      };

      const response = this.requestPost(requestBody);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  public async get_history_info(params: GetHistoryInfoParam) {
    const requestBody = {
      command: "get_history_info",
      pk: params.pk,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_like(params: PutLikeInfoParam) {
    const requestBody = {
      command: "put_like",
      range: params.range,
      userState: params.userState,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_unlike_info(params: GetUnLikeInfoParam) {
    const requestBody = {
      command: "get_unlike_info",
      userState: params.userState,
      range: params.range,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_unlike(params: PutUnLikeInfoParam) {
    const requestBody = {
      command: "put_unlike",
      pk: params.pk,
      range: params.range,
      category: params.category,
      subRange: params.subRange,
      userState: params.userState,
      inputList: params.inputList,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_history_list(params: GetMyHistoryListParam) {
    const requestBody = {
      command: "get_my_history_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_history_list(params: GetHistoryListParam) {
    const requestBody = {
      command: "get_history_list",
      userState: params.userState,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_like_list(params: GetMyLikeListParam) {
    const requestBody = {
      command: "get_my_like_list",
      userId: params.userId,
      dataText: params.dataText,
      fromDateText: params.fromDateText,
      toDateText: params.toDateText,
    };

    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_model_list() {
    const requestBody = {
      command: "get_model_list",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_model_info(params: SetModelInfo) {
    const requestBody = {
      command: "set_model_info",
      model_info: params.model_info,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async del_model_info(params: DelModelInfo) {
    const requestBody = {
      command: "del_model_info",
      request_id: params.request_id,
      gubun: params.gubun,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_model_select_list(params: GetModelSelectList) {
    const requestBody = {
      command: "get_model_select_list",
      idx: params.idx,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_model_select_list(params: SetModelSelectList) {
    const requestBody = {
      command: "set_model_select_list",
      model_select_list: params.model_select_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_replace_list() {
    const requestBody = {
      command: "get_replace_list",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async set_replace_list(params: SetReplaceList) {
    const requestBody = {
      command: "set_replace_list",
      replace_list: params.replace_list,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // qna 관리
  //--------------------------------------------------------------
  public async get_my_qna_list(params: GetQnAListParam) {
    const requestBody = { command: "get_my_qna_list", userId: params.userId };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_qna_detail_list(seq: string) {
    const requestBody = { command: "get_qna_detail_list", seq: seq };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post_question(params: PostQnaParam) {
    const requestBody = {
      command: "post_question",
      title: params.title,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async repost_question(params: RePostQuestion) {
    const requestBody = {
      command: "repost_question",
      originQseq: params.originQseq,
      title: params.title,
      content: params.content,
      userState: params.userState,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // 통계 관리
  //--------------------------------------------------------------
  public async get_statistics_list(param: GetStatisticsListParam) {
    const requestBody = {
      command: "get_statistics_list",
      search_date_from: param.searchDateFrom,
      search_date_to: param.searchDateTo,
      user_state: param.userState
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_team_statistics_list(param: GetTeamStatisticsListParam) {
    const requestBody = {
      command: "get_team_statistics_list",
      search_date_from: param.searchDateFrom,
      search_date_to: param.searchDateTo,
      team: param.team,
      user_state: param.userState
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  //--------------------------------------------------------------
  // 사용량 관리
  //--------------------------------------------------------------
  public async get_service_usage(param: GetServiceUsageParam) {
    const requestBody = {
      command: "get_service_usage",
      search_date_from: param.searchDateFrom,
      search_date_to: param.searchDateTo,
      user_state: param.userState
    };
    const response = this.requestPost(requestBody);
    return response;
  }


  //--------------------------------------------------------------
  // 공지사항
  //--------------------------------------------------------------
  public async set_notice(param: SetNoticeParam) {
    const requestBody = {
      command: "set_notice",
      notice: param.notice,
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async remove_notice(param: RemoveNoticeParam) {
    const requestBody = {
      command: "remove_notice",
      sk: param.sk
    };
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_notice(param: GetNoticeParam) {
    const requestBody = {
      command: "get_notice",
    };
    const response = this.requestPost(requestBody);
    return response;
  }

}
