import { CategoryScale, Chart } from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";

import "./ServiceUsgaeChart.css"

interface propsType {
  dataRows: any;
  type: string;
}

interface IGridCols {
  std_date: string;
  content_cnt: number;
}
Chart.register(CategoryScale);

const ServiceUsageChart = (props: propsType) => {
  const [chartData, setChartData] = useState({
    labels: props.dataRows.map((data: IGridCols) => data.std_date.substring(4, 6) + "-" + data.std_date.substring(6, 8)),
    datasets: [
      { label: "호출수", data: props.dataRows.map((data: IGridCols) => data.content_cnt) },
    ],
  });

  useEffect(() => {
    if(props.dataRows.length >= 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows.map((data: IGridCols) => data.std_date.substring(4, 6) + "-" + data.std_date.substring(6, 8)),
        datasets: [
          { label: "호출수", data: props.dataRows.map((data: IGridCols) => data.content_cnt) },
        ]
      })
    }
  }, [props.dataRows]);

  return (
    <div className="chart-root">
      {props.type === "bar" ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "일별 API 호출현황",
              },
              legend: {
                display: true,
                position: "top",
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "일자",
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "건수",
                },
              },
            },
          }}
        />
      ) : (
        <Line
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: "일별 API 호출현황",
              },
              legend: {
                display: true,
                position: "top",
              },
            },
            interaction: {
              intersect: false,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "일자",
                },
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "건수",
                },
                ticks: {
                  callback: function(value) {
                    return Number.isInteger(value) ? value : null;
                  }
                }
              },
            },
          }}
        />
      )}
    </div> 
  )
}

export default ServiceUsageChart;