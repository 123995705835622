export const appConfig = {
  projectName: "moisProd",
  systemNameEng: "mois",
  systemName: "모이스",
  systemNameExam: "모이스가",
  mainApiUrl:
    "https://idy6qii69e.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  testApiUrl:
    "https://idy6qii69e.execute-api.ap-northeast-2.amazonaws.com/dev/adminMain",
  RecommendApiUrl:
    "https://fofgvlkqx45xwz43hvywd6r3um0pecut.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "moisadminss", // 사이트마다 달라야 함
  rememberidSSName: "moisrmbrid", // 사이트마다 달라야 함
  showTerms: true, // true : 사용자로그인, false : H-ART 로그인
  seed: "22333",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "moisQnaInfo",
};
