import React, { useEffect, useState, useRef } from "react";
import CryptoJS from "crypto-js";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Toast from "../../utils/Toast";
import { HttpMainApi, LoginProcParam } from "../../interface/main-api";
import { appConfig } from "../../config/Config";
import * as AuthMain from "../../auth/AuthMain";

import "./Login.css";

interface propsType {}

const Login = (props: propsType) => {
  const mainApi = new HttpMainApi();
  const [userId, setUserId] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [rememberId, setRememderId] = useState(false);
  const [checkRememberId, setCheckRememberId] = useState(false);
  const [needLogin, setNeedLogin] = useState(true);
  const pwdRef: any = useRef();
  const toastRef: any = useRef();

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncProcLogin();
  };

  // 로그인 처리
  const fncProcLogin = async () => {
    if (userId === "" || userId.trim() === "") {
      toastRef.current?.toast("아이디를 입력하세요.", "warning", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (userPwd === "" || userPwd.trim() === "") {
      toastRef.current?.toast("패스워드를 입력하세요.", "warning", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: LoginProcParam = {
      id: userId,
      password: userPwd,
    };

    const res = await mainApi.login_proc(param);
    if ("" + res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
      else procFail(res.response);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    toastRef.current?.toast("로그인 성공", "info", 3000, {
      vertical: "top",
      horizontal: "center",
    });
    AuthMain.setSession(sessionData);
    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      id: sessionData.id,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (rememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }
    window.location.href = "/agree";
  };

  // 로그인 실패
  const procFail = (res: any) => {
    toastRef.current?.toast(res.result_msg, "error", 3000, {
      vertical: "top",
      horizontal: "center",
    });
  };

  useEffect(() => {
    const result = AuthMain.getSession();
    if (result !== null) {
      setNeedLogin(false);
      window.location.href = "/";
    } else {
      setNeedLogin(true);
    }
    const objString = window.localStorage.getItem(appConfig.rememberidSSName);
    if (objString !== null) {
      const obj = JSON.parse(objString);
      if (obj.remember === "TRUE") {
        setRememderId(true);
        setUserId(obj.id);
        setCheckRememberId(true);
        pwdRef.current.focus();
      }
    }
  }, []);

  useEffect(() => {
    if (!rememberId && checkRememberId) {
      window.localStorage.removeItem(appConfig.rememberidSSName);
    }
  }, [rememberId]);

  return (
    <>
      {needLogin ? (
        <div className="login-root">
          <div className="login-wrapper">
            <div className="login-box">
              <Stack spacing={2} direction="column">
                <h4>
                  <img
                    src="/images/logo-mois.png"
                    className="login-logo"
                    alt="logo"
                  />
                </h4>
                {/* <Typography>
                  AI 카피라이터 {appConfig.systemName}
                </Typography> */}
                <div className="TextField-without-border-radius">
                  <TextField
                    id="txtUserId"
                    value={userId}
                    placeholder="아이디를 입력해주세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    autoComplete="on"
                    sx={{ maxWidth: "400px" }}
                    inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setUserId(e.target.value);
                    }}
                  />
                </div>
                <div className="TextField-without-border-radius">
                  <TextField
                    inputRef={pwdRef}
                    value={userPwd}
                    placeholder="패스워드를 입력해주세요."
                    variant="outlined"
                    type="password"
                    autoComplete="current-password"
                    fullWidth
                    sx={{ maxWidth: "400px" }}
                    inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                    onChange={(e) => {
                      setUserPwd(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      handlePasswordEnter(e);
                    }}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      maxWidth: "400px",
                      borderRadius: "25px",
                      height: "44px",
                      mt: 3,
                    }}
                    color="primary"
                    onClick={() => {
                      fncProcLogin();
                    }}
                  >
                    로그인
                  </Button>
                </div>
              </Stack>
            </div>
            <div className="copy-warapper">
              <p className="copyright">©SAMSUNG CARD CO., LTD. All rights reserved.</p>
            </div>
          </div>
          <Toast ref={toastRef} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;
