import { Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { userState } from "../../interface/MainInterface"
import { useCallback, useEffect, useRef, useState } from "react";
import { GetNoticeParam, HttpMainApi, RemoveNoticeParam, SetNoticeParam } from "../../interface/main-api";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState : userState
}

const mainApi = new HttpMainApi();

const MngNotice = (props: propsType) => {
  const toastRef: any = useRef();

  const [isLoading, setLoading] = useState(false);
  const [notice, setNotice] = useState("");
  const [sk, setSk] = useState("");

  const fncGetNotice = useCallback(async () => {
    setLoading(true);

    const params : GetNoticeParam = {
      userState: props.userState
    }

    const res = await mainApi.get_notice(params);
    if(res.code === "200") {
      if("response" in res) {
        setNotice(res.response.notice);
        setSk(res.response.sk);
      }
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [])

  const fncSetNotice = useCallback(async () => {
    if(notice === "") {
      toastRef.current?.toast("공지사항을 입력해주세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    let confirmText = '저장하시겠습니까?'
    if(!window.confirm(confirmText)) return;    

    setLoading(true);

    const params : SetNoticeParam = {
      notice: notice,
      userState: props.userState
    }

    const res = await mainApi.set_notice(params);
    if(res.code === "200") {
      toastRef.current?.toast("공지사항 업데이트 성공", "success", 3000, { vertical: "top", horizontal: "center" });
      setNotice(res.response.notice);
      setSk(res.response.sk);
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [notice]);

  const fncRemoveNotice = useCallback(async () => {
    if(sk === "") {
      toastRef.current?.toast("공지사항이 없습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    let confirmText = '삭제하시겠습니까?'
    if(!window.confirm(confirmText)) return;    

    setLoading(true);

    const params : RemoveNoticeParam = {
      sk: sk,
      userState: props.userState
    }

    const res = await mainApi.remove_notice(params);
    if(res.code === "200") {
      toastRef.current?.toast("공지사항 삭제 성공", "success", 3000, { vertical: "top", horizontal: "center" });
      setNotice("");
      setSk("");
    } else {
      toastRef.current?.toast(res.response.error_msg, "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setLoading(false);
  }, [sk])
  
  useEffect(() => {
    fncGetNotice();
  }, [])

  return (
  <div>
    <Grid container spacing={1} sx={{ paddingX: 1}} component="div">
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
            공지사항 관리
        </Typography>
        <Paper sx={{ p: 2}} elevation={5}>
          <Stack spacing={2} direction="row" alignItems="center">
            <Grid item xs={20}>
              <Stack direction={{ xs: "column", sm: "row"}} spacing={1}>
                <TextField 
                  id="txtTeam"
                  label="공지사항"
                  variant="outlined"
                  size="small"
                  value={notice}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter"}}
                  onChange={(e) => {
                    setNotice(e.target.value);
                  }}
                  required
                  fullWidth
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={1} direction="row" justifyContent="flex-end" >
                <Button color="secondary" variant="contained" onClick={fncRemoveNotice}>
                  삭제
                </Button>
                <Button variant="contained" onClick={fncSetNotice}>
                  저장
                </Button>
              </Stack>
            </Grid>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
    <LoadingCircle loading={isLoading} />
    <Toast ref={toastRef} />
  </div>)
}

export default MngNotice;