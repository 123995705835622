import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import PulseLoader from "react-spinners/PulseLoader";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import {
  HttpMainApi,
  MultiGptContent,
  GetHistoryInfoParam,
} from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { useInterval } from "../../utils/UseInterval";

import Copy from "../reaction/Copy";
import MultiLike from "../reaction/MultiLike";
import MultiUnLike from "../reaction/MultiUnLike";

import "./CopyWriter.css";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

interface propsType {
  userState: userState;
  idx: string;
  teamType: string;
  dataText: string;
  joinText: string;
  finishProc: any;
  reloadMyHistory: any;
}

const mainApi = new HttpMainApi();

const MultiCopyResult = (props: propsType, ref: any) => {
  const swiperRef = useRef<any>(null);
  const [pk, setPk] = useState("");
  const [range, setRange] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [toolTipShow, setToolTipShow] = useState(false);

  const [title, setTitle] = useState("");

  const [historyInfo, setHistoryInfo] = useState<any>([]);
  const [slideToNum, setSlideToNum] = useState(0);

  const [pollingTitle, setPollingTitle] = useState(false);
  const [pollingContents, setPollingContents] = useState(false);

  const [viewStatus, setViewStatus] = useState(false); // 결과 보여주기용 핸들러

  const [memeList, setMemeList] = useState([]);
  const [open, setOpen] = useState(false);

  const makeTitle = async () => {
    const param: MultiGptContent = {
      type: "title",
      idx: props.idx,
      teamType: props.teamType,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.multi_make_gpt_copy(param);
    if (res.code === "200") {
      setTitle(res.response.result);
      makeContents(res.response.pk, res.response.range, res.response.result);
    } else {
      console.error("Title Error : ", res);
      if (res.response.error_msg === "apiTimeout") {
        setPollingTitle(true);
        setTimeout(() => {
          if (pollingTitle) {
            setPollingTitle(false);
            props.finishProc(props.idx, "fail");
          }
        }, 120000);
      } else {
        props.finishProc(props.idx, "fail");
      }
    }
  };

  const makeContents = async (
    pk: string,
    range: string,
    titleResult: string
  ) => {
    const param: MultiGptContent = {
      type: "contents",
      idx: props.idx,
      teamType: props.teamType,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: titleResult,
      pk: pk,
      range: range,
    };
    const res = await mainApi.multi_make_gpt_copy(param);
    if (res.code === "200") {
      setPk(res.response.pk);
      setRange(res.response.range);
      setMemeList(res.response.meme_list);
      props.finishProc(props.idx, "success");
    } else {
      console.error("Content Error : ", res);
      if (res.response.error_msg === "apiTimeout") {
        setPollingContents(true);
        setTimeout(() => {
          if (pollingContents) {
            setPollingContents(false);
            props.finishProc(props.idx, "fail");
          }
        }, 120000);
      } else {
        props.finishProc(props.idx, "fail");
      }
    }
  };

  useInterval(
    async () => {
      const result = await pollingTitleInfo();
    },
    pollingTitle ? 3000 : null
  );

  useInterval(
    async () => {
      const result = await pollingContentsInfo();
      if (result) setPollingContents(false);
    },
    pollingContents ? 3000 : null
  );

  const pollingTitleInfo = async () => {
    const param: MultiGptContent = {
      type: "title",
      idx: props.idx,
      teamType: props.teamType,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.poll_gpt_info(param);
    if ("" + res.code === "200") {
      setTitle(res.response.result);
      makeContents(res.response.pk, res.response.range, res.response.result);
      setPollingTitle(false);
    } else {
      console.error("pollingTitleInfo ERROR : ", res.response.error_msg);
    }
  };

  const pollingContentsInfo = async () => {
    const param: MultiGptContent = {
      type: "contents",
      idx: props.idx,
      teamType: props.teamType,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: "",
      pk: "",
      range: "",
    };

    const res = await mainApi.poll_gpt_info(param);
    if ("" + res.code === "200") {
      setPk(res.response.pk);
      setRange(res.response.range);
      props.finishProc(props.idx, "success");
      setPollingContents(false);
      return true;
    } else {
      console.error("pollingContentsInfo ERROR : ", res.response.error_msg);
      return false;
    }
  };

  // 카피 만들기 (본문 추가생성)
  const makeContentsMore = async () => {
    if (isLoading) return;

    const param: MultiGptContent = {
      type: "contents",
      idx: props.idx,
      teamType: props.teamType,
      userState: props.userState,
      brand_text: props.dataText,
      add_keyword: props.joinText,
      add_title: title,
      pk: pk,
      range: range,
    };
    setIsLoading(true);
    setToolTipShow(false);
    setMemeList([]);
    const res = await mainApi.multi_make_gpt_copy(param);
    if ("" + res.code === "200") {
      setMemeList(res.response.meme_list);
      get_history_info();
    } else {
      // setLoading(false);
      console.error("makeContentsMore ERROR : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  // 결과조회
  const get_history_info = async () => {
    if (slideToNum % 3 === 0) {
      setToolTipShow(true);
    } else {
      setToolTipShow(false);
    }

    const param: GetHistoryInfoParam = {
      pk: pk,
      range: range,
    };

    const res = await mainApi.get_history_info(param);
    if ("" + res.code === "200") {
      setHistoryInfo(res.response.data_info);
      setSlideToNum(res.response.data_info[0][2].length);
    } else {
      console.error("get_history_info ERROR : ", res.response.error_msg);
    }
  };

  // 본문 결과가 2건 이상시 마지막으로 이동
  useEffect(() => {
    if (historyInfo.length > 0) {
      swiperRef.current?.swiper.slideTo(historyInfo[0][2].length);
    }
  }, [historyInfo]);

  // 슬라이더 변경 및 결과 노출 여부에 따라서 결과 조회
  useEffect(() => {
    if (pk !== "" && range !== "" && viewStatus) get_history_info();
  }, [pk, range, viewStatus, slideToNum]); //[] 호출시마다 조회

  // 결과 form 보여주기/감추기 핸들러(부모창에서 호출)
  const changeViewStatus = (flag: boolean) => {
    setViewStatus(flag);
  };
  const [copyContent, setCopyContent] = useState("제목 복사");
  const titleCopy = useCallback(async (text: string) => {
    try {
      setCopyContent("복사 완료");
      setTimeout(() => {
        setCopyContent("문장 복사");
      }, 2000);
      await navigator.clipboard.writeText(text);
    } catch (error) {}
  }, []);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    makeTitle,
    changeViewStatus,
  }));

  const reactionCallback = () => {
    props.reloadMyHistory();
  };

  const handleClose = () => {
    setOpen(false);
  }
  
  const handleOpen = () => {
    setOpen(true);
  }

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: "1rem",
        display: "flex",
        position: "relative",
        ...(!viewStatus && { display: "none" }),
      }}
      elevation={3}
    >
      {historyInfo.map((item: any, index: number) => (
        <div className="result-wrapper" key={index}>
          <div
            className={
              props.idx === "1"
                ? "type-notice bg-orange"
                : "type-notice bg-green"
            }
          >
            {props.idx === "2" ? "카피B" : "카피A"}
          </div>
         
          <Stack direction="column" spacing={2.5} sx={{ pt: 2 }}>
            <Tooltip
              title={copyContent}
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                    fontSize: "0.8rem",
                  },
                },
              }}
              arrow
            >
              <Typography
                component="span"
                className="result-title"
                onClick={() => {
                  titleCopy(item[1][0].title);
                }}
              >
                {isLoading ? <Skeleton /> : item[1][0].title}
              </Typography>
            </Tooltip>
            <Typography component="span" className="result-content">
              {isLoading ? (
                <Skeleton />
              ) : (
                <p style={{ whiteSpace: "pre-wrap" }}>{item[2][item[2].length - 1].content}</p>
              )}
            </Typography>

            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <>
                  {props.teamType === "G" && memeList.length > 0 ? <>
                    <Button disableRipple className="btn-meem-list" onClick={handleOpen}>밈 내역</Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      >
                      <Box sx={{
                          position: "absolute" as "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: { xs: "80%", md: "50%", lg: "40%" },
                          maxWidth: "90%",
                          maxHeight: "90%",
                          bgcolor: "background.paper",
                          border: "0px solid #000",
                          borderRadius: "1rem",
                          boxShadow: 24,
                          p: 3,
                          zIndex: 990,
                          overflow: 'scroll'
                        }}>
                          <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: "#1561C4", fontWeight: "bold" }} >
                                  참고한 밈 
                                </Typography>
                                <Typography id="modal-modal-title" sx={{ color: "#FF0000", fontSize: "13px"}}>
                                  * 최근 두 달 밈으로 랜덤 적용됩니다.
                                </Typography>
                              </Stack>
                            <Tooltip title="창닫기">
                              <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                            </Stack>
                              {memeList.map((item: any, index: number) => (
                                <Stack direction="column" spacing={1}>
                                  <Stack direction="row" spacing={1} sx={{display: "flex", alignItems: "center"}}>
                                    <Box className="btn-meem-detail">
                                      <Typography sx={{fontSize: "14px", fontWeight: "bold", color: "#1561C4"}}>
                                        밈 {String(index + 1).padStart(2, '0')} 
                                      </Typography>
                                    </Box>
                                    <Typography id="modal-modal-description" sx={{fontSize: "14px", fontWeight: "bold"}}>
                                      {item.meme}
                                    </Typography>
                                  </Stack>
                                  <Typography id="modal-modal-description" sx={{ fontSize: "14px", pb: 1.5 }}>
                                      {item.desc}
                                  </Typography>
                                </Stack>
                              ))}
                          </Stack>
                      </Box>
                    </Modal>
                  </> : <></>}
                </>
                <Tooltip
                  title={"키워드를 바꾸면 다양한 문장이 만들어집니다."}
                  placement="left"
                  open={toolTipShow}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        fontSize: "0.8rem",
                      },
                    },
                  }}
                  arrow
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className="btn-make-more"
                    onClick={makeContentsMore}
                  >
                    {isLoading ? (
                      <PulseLoader
                        color="#167BFF"
                        cssOverride={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                        size={9}
                      />
                    ) : (
                      "본문추가생성"
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <div className="swiper mySwiper result-list">
              <div>
                <Swiper
                  ref={swiperRef}
                  id="swiper"
                  tag="section"
                  loop={false}
                  navigation={true}
                  pagination={{ type: "fraction" }}
                  slidesPerView={1}
                  onSlideChange={(swiper) => {}}
                >
                  {item[2].map((contentList: any, index: number) => (
                    <SwiperSlide key={contentList.content_no}>
                      <div className="swiper-slide">
                        <div className="slide-cont">
                          <dl>
                            <dd style={{ whiteSpace: "pre-wrap" }}>
                              {contentList.content}
                            </dd>
                          </dl>
                          <div className="slide-icon-wrap">
                            <Copy
                              userState={props.userState}
                              copyText={contentList.content}
                            />
                            <MultiLike
                              range={contentList.range}
                              userState={props.userState}
                              callBack={reactionCallback}
                            />
                            <MultiUnLike
                              pk={contentList.pk}
                              range={contentList.content_no}
                              category={"content"}
                              subRange={contentList.range}
                              userState={props.userState}
                              inputList={item[0].input_list}
                              callBack={reactionCallback}
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Stack>
        </div>
      ))}
    </Paper>
  );
};

export default forwardRef(MultiCopyResult);
