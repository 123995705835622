import { useEffect, useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import { HttpMainApi, GetLikeInfoParam, PutLikeInfoParam } from "../../interface/main-api";
import { appConfig } from "../../config/Config";

interface propsType {
  range: string;
  userState: userState;
  isLike: boolean;
}

const mainApi = new HttpMainApi();

const Like = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-up");

  const setClickLike = async () => {
    const param: PutLikeInfoParam = {
      range: props.range,
      userState: props.userState,
    };
    const res = await mainApi.put_like(param);
    if ("" + res.code === "200") {
      res.response.result.isLike === true
        ? setClassName("fas fa-icon fa-thumbs-up active")
        : setClassName("fas fa-icon fa-thumbs-up");
    } else {
      console.error("setClickLike ERROR : ", res.response.error_msg);
    }
  };

  const check_like_info = async () => {
    if(props.isLike) {
      setClassName("fas fa-icon fa-thumbs-up active")
    } else {
      setClassName("fas fa-icon fa-thumbs-up");
    }
  }

  useEffect(() => {
    check_like_info();
  }, []); //[] 변경시마다  작동

  return <i className={className} onClick={setClickLike} />;
};

export default Like;
