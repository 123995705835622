/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useCallback, useRef } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import { HttpMainApi, GetDashboardParam } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import PolarChart from "./PolarChart";
import HistoryResultSmall from "../copyWriter/HistoryResultSmall";
import "./DashBoard.css";

interface propsType {
  userState: userState;
}

interface IBrandGridCols {
  num: number;
  brand_name: string;
}

const adminApi = new HttpMainApi();

const DashBoard = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setLoading] = useState(false);

  const gridApi = useGridApiRef();
  const gridKeywordApi = useGridApiRef();

  const [fromDateValue, setFromDateValue] = useState<any>(dayjs().add(-7, "d"));
  const [toDateValue, setToDateValue] = useState<any>(dayjs());

  const [copyCnt, setCopyCnt] = useState("");
  const [likeCnt, setLikeCnt] = useState("");
  const [unlikeCnt, setUnlikeCnt] = useState("");
  const [myCopyCnt, setMyCopyCnt] = useState("");
  const [myLikeCnt, setMyLikeCnt] = useState("");
  const [myUnlikeCnt, setMyUnlikeCnt] = useState("");

  const [productRank, setProductRank] = useState<any>({});
  const [productRankDetail, setProductRankDetail] = useState<any>([]);
  const [productResult, setProductResult] = useState<any>({});
  const [selectedProductRow, setSelectedProductRow] = useState<GridRowSelectionModel>([]);
  const [productRows, setProductRows] = useState<IBrandGridCols[]>([]);

  const [keywordRank, setKeywordRank] = useState<any>({});
  const [keywordRankDetail, setKeywordRankDetail] = useState<any>([]);
  const [keywordResult, setKeywordResult] = useState<any>({});
  const [selectedKeywordRow, setSelectedKeywordRow] = useState<GridRowSelectionModel>([]);
  const [keywordRows, setKeywordRows] = useState<IBrandGridCols[]>([]);

  const [historyList, setHistoryList] = useState<any>([]);
  const [historyKeywordList, setHistoryKeywordList] = useState<any>([]);

  const productColumn: GridColDef[] = [
    { field: "num", headerName: "num", headerAlign: "center", align: "center", width: 60 },
    { field: "brand_name", headerName: "brand_name", headerAlign: "center", align: "center", flex: 1 },
    {
      field: "temp",
      headerName: "Btn",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <Stack spacing={0} direction="row">
              <IconButton onClick={() => fncDetail(param.row.brand_name)}>
                <SearchIcon className="color-red" />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  const keywordColumn: GridColDef[] = [
    { field: "num", headerName: "num", headerAlign: "center", align: "center", width: 60 },
    { field: "brand_name", headerName: "brand_name", headerAlign: "center", align: "center", flex: 1 },
    {
      field: "temp",
      headerName: "Btn",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <Stack spacing={0} direction="row">
              <IconButton onClick={() => fncDetailKeyword(param.row.brand_name)}>
                <SearchIcon className="color-red" />
              </IconButton>
            </Stack>
          </>
        );
      },
    },
  ];

  const fncGetDashboard = useCallback(async () => {
    const param: GetDashboardParam = {
      userState: props.userState,
      fromDateText: dayjs(fromDateValue).format("YYYYMMDD"),
      toDateText: dayjs(toDateValue).format("YYYYMMDD"),
    };

    const res = await adminApi.get_dashboard(param);
    if (res.code === "200") {
      setCopyCnt(res.response.copy_cnt);
      setLikeCnt(res.response.like_cnt);
      setUnlikeCnt(res.response.unlike_cnt);
      setMyCopyCnt(res.response.my_copy_cnt);
      setMyLikeCnt(res.response.my_like_cnt);
      setMyUnlikeCnt(res.response.my_unlike_cnt);
      setProductRows(res.response.top_product);
      setKeywordRows(res.response.top_keyword);
      setProductRank(res.response.product_rank);
      setKeywordRank(res.response.keyword_rank);
      setProductResult(res.response.product_result);
      setKeywordResult(res.response.keyword_result);
      if(res.response.top_product.length > 0)
        setSelectedProductRow(res.response.top_product[0].brand_name);
      if(res.response.top_keyword.length > 0)
        setSelectedKeywordRow(res.response.top_keyword[0].brand_name);
    } else {
      toastRef.current?.toast("대시보드 조회 중 에러가 발생했습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      console.error("fncGetDashboard ERROR : ", res.response.error_msg);
    }
  }, [props.userState, fromDateValue, toDateValue]);

  const fncDetail = useCallback((brand_name: string) => {
      const result = productRank[brand_name];
      setProductRankDetail(result);
      const list = productResult[brand_name];
      setHistoryList(list);
    }, [productRank, productResult]);

  const fncDetailKeyword = useCallback((brand_name: string) => {
      const result = keywordRank[brand_name];
      setKeywordRankDetail(result);
      const list = keywordResult[brand_name];
      setHistoryKeywordList(list);
    }, [keywordRank, keywordResult]);

  useEffect(() => {
    if (!props.userState.id) {
      window.location.href = "/login";
      return;
    }
    fncGetDashboard();
  }, []);

  useEffect(() => {
    if (productRows.length > 0 && productRank) {
      const result = productRank[productRows[0].brand_name];
      setProductRankDetail(result);
      const list = productResult[productRows[0].brand_name];
      setHistoryList(list);
    }
  }, [productRows, productRank, productResult]);

  useEffect(() => {
    if (keywordRows.length > 0 && keywordRank) {
      const result = keywordRank[keywordRows[0].brand_name];
      setKeywordRankDetail(result);
      const list = keywordResult[keywordRows[0].brand_name];
      setHistoryKeywordList(list);
    }
  }, [keywordRows, keywordRank, keywordResult]);

  return (
    <div>
      <Box>
        {/* 상단영역 */}
        <Box component={"div"}>
          <Paper sx={{ pt: "10px", pl: 1, pr: 1, pb: 1 }} elevation={5}>
            <Grid container spacing={1} sx={{ paddingX: 0 }} component="div">
              <Grid item xs={12} md={6} lg={3}>
                <Stack spacing={1} direction="row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      value={fromDateValue}
                      onChange={(newValue) => setFromDateValue(newValue)}
                      slotProps={{ textField: { size: "small", autoComplete: "off", fullWidth: true } }}
                    />
                    <DatePicker
                      label="To"
                      value={toDateValue}
                      onChange={(newValue) => setToDateValue(newValue)}
                      slotProps={{ textField: { size: "small", autoComplete: "off", fullWidth: true } }}
                    />
                  </LocalizationProvider>
                  <Button
                    variant="contained"
                    sx={{ height: "41px" }}
                    onClick={() => {
                      fncGetDashboard();
                    }}
                  >
                    조회
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={2.5}>
                <TextField
                  id="txtCopyCnt"
                  label="부서"
                  variant="outlined"
                  size="small"
                  value={props.userState.userInfo2}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={12} lg={3.25}>
                <Stack spacing={1} direction="row">
                  <TextField
                    id="txtCopyCnt"
                    label="카피수"
                    variant="outlined"
                    size="small"
                    value={copyCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    fullWidth
                  />
                  <TextField
                    id="txtLikeCnt"
                    label="좋아요"
                    variant="outlined"
                    size="small"
                    value={likeCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    fullWidth
                  />
                  <TextField
                    id="txtUnlikeCnt"
                    label="싫어요"
                    variant="outlined"
                    size="small"
                    value={unlikeCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    fullWidth
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12} lg={3.25}>
                <Stack spacing={1} direction="row">
                  <TextField
                    id="txtMyCopyCnt"
                    label="나의카피수"
                    variant="outlined"
                    size="small"
                    value={myCopyCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setMyCopyCnt(e.target.value);
                    }}
                    fullWidth
                  />
                  <TextField
                    id="txtMyLikeCnt"
                    label="나의좋아요"
                    variant="outlined"
                    size="small"
                    value={myLikeCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    fullWidth
                  />
                  <TextField
                    id="txtMyUnlikeCnt"
                    label="나의싫어요"
                    variant="outlined"
                    size="small"
                    value={myUnlikeCnt}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    fullWidth
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        {/* 상단영역 */}
        {/* 제품/브랜드 영역 */}
        <Box component={"div"} sx={{ mt: 0.9 }}>
          <Typography variant="h6">가장 많이 생성한 제품/브랜드</Typography>
        </Box>
        <Box component={"div"}>
          <Grid container spacing={1} sx={{ paddingX: 0, display: 'flex' }} component="div">
            <Grid item xs={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column'}}>
              <Paper sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column'}} elevation={5}>
                <Stack spacing={2} direction="column">
                  <Typography variant="subtitle1">인기 제품/브랜드(검색량순)</Typography>
                  <div className={"datatable-top-brand"}>
                    <DataGrid
                      apiRef={gridApi}
                      rows={productRows}
                      columns={productColumn}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20, 50, 100]}
                      getRowId={(row) => row.brand_name}
                      hideFooter
                      columnHeaderHeight={0}
                      rowSelectionModel={selectedProductRow}
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedProductRow(newRowSelectionModel);
                      }}
                    />
                  </div>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3}  sx={{display: 'flex', flexDirection: 'column'}}>
              <Paper sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column' }} elevation={5}>
                <Stack spacing={2} direction="column">
                  <Typography variant="subtitle1">연계 키워드(검색량순)</Typography>
                  <div className="polar-chart-wrapper">
                    <div className="polar-chart">
                      <PolarChart dataRows={productRankDetail} />
                    </div>
                  </div>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={6} sx={{display: 'flex', flexDirection: 'column'}}>
              <Paper sx={{ p: 1, minHeight: "321px", flex: 1, display: 'flex', flexDirection: 'column' }} elevation={5}>
                <Stack spacing={1} direction="column">
                  <Typography variant="subtitle1">선호도 TOP3(좋아요순)</Typography>
                  {historyList.slice(0, 3).map((item: any, index: number) => (
                    <HistoryResultSmall
                      key={item[1]}
                      userState={props.userState}
                      pk={item[0]}
                      range={item[1]} />
                  ))}
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* 제품/브랜드 영역 */}
      {/* 키워드 영역 */}
      <Box component={"div"} sx={{ mt: 0.9 }}>
        <Typography variant="h6">가장 많이 생성한 테마/키워드/시즌</Typography>
      </Box>
      <Box component={"div"}>
        <Grid container spacing={1} sx={{ paddingX: 0, display: 'flex' }} component="div">
          <Grid item xs={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column'}}>
            <Paper sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column'}} elevation={5}>
              <Stack spacing={2} direction="column">
                <Typography variant="subtitle1">인기 테마/키워드/시즌(검색량순)</Typography>
                <div className={"datatable-top-brand"}>
                  <DataGrid
                    apiRef={gridKeywordApi}
                    rows={keywordRows}
                    columns={keywordColumn}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    getRowId={(row) => row.brand_name}
                    hideFooter
                    columnHeaderHeight={0}
                    rowSelectionModel={selectedKeywordRow}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedKeywordRow(newRowSelectionModel);
                    }}
                  />
                </div>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{display: 'flex', flexDirection: 'column'}}>
            <Paper sx={{ p: 1, flex: 1, display: 'flex', flexDirection: 'column'}} elevation={5}>
              <Stack spacing={2} direction="column">
                <Typography variant="subtitle1">연계 키워드(검색량순)</Typography>
                <div className="polar-chart-wrapper">
                  <div className="polar-chart">
                    <PolarChart dataRows={keywordRankDetail} />
                  </div>
                </div>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <Paper sx={{ p: 1, minHeight: "321px", flex: 1, display: 'flex', flexDirection: 'column' }} elevation={5}>
              <Stack spacing={1} direction="column" sx={{flex: 1}}>
                <Typography variant="subtitle1">선호도 TOP3(좋아요순)</Typography>
                {historyKeywordList.slice(0, 3).map((item: any, index: number) => (
                  <HistoryResultSmall
                    key={item[1]}
                    userState={props.userState}
                    pk={item[0]}
                    range={item[1]} />
                ))}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* 키워드 영역 */}
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </div>
  );
};

export default DashBoard;
